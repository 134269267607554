import * as React from "react";
import { graphql } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";

export const fragment = graphql`
  fragment MediaItem on WpMediaItem {
    srcSet
    sourceUrl
    altText
    id
    localFile {
      childImageSharp {
        gatsbyImageData(placeholder: BLURRED, formats: [AUTO, WEBP, AVIF])
      }
    }
    mimeType
  }
`;

const Image = ({ image, classes }) => {
  if (
    !image?.localFile.childImageSharp &&
    image?.mimeType === "image/svg+xml"
  ) {
    return (
      <img
        className={`block ${classes}`}
        src={image?.sourceUrl}
        alt={image?.altText}
      />
    );
  } else {
    return (
      <GatsbyImage
        className={`block w-full ${classes}`}
        image={image?.localFile.childImageSharp.gatsbyImageData}
        alt={image?.altText}
      />
    );
  }
};

export default Image;
