import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLongArrowDown } from "@fortawesome/pro-light-svg-icons";

const getDirection = (dir) => {
  switch (dir) {
    case "down":
      return false;
    case "right":
      return "-rotate-90";
    case "left":
      return "rotate-90";
    case "up":
      return "rotate-180";
    default:
      return false;
  }
};

const Arrow = ({ size = 14, direction = false, icon = null, theme = null }) => {
  const dir = getDirection(direction);

  const width = size > 14 ? "w-16" : `w-${size}`;
  const height = size > 14 ? "h-16" : `h-${size}`;

  const mdWidth = `md:w-${size}`;
  const mdHeight = `md:h-${size}`;

  const themeClass = theme === "white" ? `theme-white` : `theme-ocean`;

  return (
    <div
      className={`${themeClass} ${width} ${height} ${mdWidth} ${mdHeight} bg-skin-arrow-base text-skin-base rounded-full grid place-items-center flex-none transform ${dir} outline-none hover-hover:group-hover:bg-skin-arrow-inverted hover-hover:group-hover:text-skin-inverted`}
    >
      {!icon && (
        <FontAwesomeIcon
          icon={faLongArrowDown}
          width={18}
          height={36}
          className={`${size > 14 ? "text-4xl" : "text-2xl"} max-h-12`}
        />
      )}
      {icon && icon}
    </div>
  );
};

export default Arrow;
