import * as React from "react";
import Arrow from "./Arrow";
import { Link } from "gatsby";

const Button = ({ text, url, target, icon, size = 10, classes }) => {
  return (
    <Link
      to={url ? url : "#"}
      target={target}
      className={`relative group flex items-center transition duration-250 hover-hover:hover:bg-ocean ${classes}`}
    >
      <Arrow size={size} direction={!icon ? "right" : false} icon={icon} />
      <span className="ml-3 font-bold text-lg">{text}</span>
    </Link>
  );
};

export default Button;
