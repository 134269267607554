import React from "react";
import { Link } from "gatsby";

const Nav = ({ data, links }) => {
  return (
    <nav className="ml-auto font-body block">
      <ul className="flex h-full">
        {links.map((link, i) => {
          return (
            <li key={link.id} className={``}>
              <Link
                to={link.url}
                className="grid place-items-center h-full px-8 md:px-12 font-bold transition duration-100 hover:bg-ocean"
              >
                {link.title}
              </Link>
            </li>
          );
        })}
      </ul>
    </nav>
  );
};

export default Nav;
