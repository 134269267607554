import React, { useState } from "react";
import { Link, useStaticQuery, graphql } from "gatsby";
import Logo from "../components/Logo";
import Nav from "../components/Nav";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars } from "@fortawesome/pro-light-svg-icons";

const Header = () => {
  const [mobileMenu, setMobileMenu] = useState(false);

  const links = [
    {
      id: "contact",
      title: "Contact",
      url: "#contact",
    },
  ];

  return (
    <header className="relative py-4 md:py-0 lg:container mx-auto z-50 w-full flex bg-oxford text-white font-second">
      <Link
        to="/"
        className="py-4 md:py-8 px-6 md:px-16 grid place-items-center "
      >
        <Logo className="w-[174.75px] h-[24.75px] md:w-[230px] md:h-[33px]" />
        <h1 className="sr-only">Anchor Brook</h1>
      </Link>
      <Nav links={links} />
    </header>
  );
};

export default Header;
